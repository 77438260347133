import React, {Component} from 'react';

import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import './VideoToolbar.css';

export default class VideoToolbar extends Component {
    constructor(props) {
        super(props);

        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.handleFinishSession = this.handleFinishSession.bind(this);
    }

    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    handleFinishSession() {
        this.props.leaveSession();
    }

    render() {
        const {isAudioActive, isVideoActive, isDisabled} = this.props;
        return (
            <Toolbar className="toolbar bg-lightgray">
                <div className="buttonsContent">
                    <IconButton color="inherit" className="navButton" id="navMicButton"
                                onClick={this.micStatusChanged} disabled={isDisabled}>
                        {isAudioActive ? <Mic/> : <MicOff color="secondary"/>}
                    </IconButton>

                    <IconButton color="inherit" className="navButton" id="navCamButton"
                                onClick={this.camStatusChanged} disabled={isDisabled}>
                        {isVideoActive ? <Videocam/> : <VideocamOff color="secondary"/>}
                    </IconButton>
                    <IconButton color="secondary" className="navButton" onClick={this.handleFinishSession}
                                id="navLeaveButton" disabled={isDisabled}>
                        <PowerSettingsNew/>
                    </IconButton>
                </div>
            </Toolbar>
        );
    }
}