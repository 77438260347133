import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {TextField} from "@material-ui/core";

const styles = theme => ({
    doctorContainer: {
        marginTop: '15px',
        padding: '10px 15px',
        fontSize: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    font: {
        fontSize: '20px'
    },
    field: {
        fontSize: '20px',
        display: 'flex',
    },
    resize: {
        fontSize: 20
    }
});

function DoctorName(props) {
    const {classes, disabled, value, onChange} = props;
    return (
        <Paper elevation={3} className={classes.doctorContainer}>
            <TextField
                disabled={disabled}
                value={value}
                onChange={onChange}
                label="Ваше имя"
                InputProps={{
                    classes: {
                        input: classes.field,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.font
                    }
                }}
                className={classes.font}
            />
        </Paper>
    )
}


export default withStyles(styles)(DoctorName);