import API, {patientAPI} from "../api"
import axios from "axios"
import {OPENVIDU_SERVER_SECRET, OPENVIDU_SERVER_URL} from "../constants"


export function handleSignInWithEmailAndPassword(email, password){
    return new Promise((resolve, reject) => {
        API.post('/auth/login', {
            'login': email,
            'password': password,
        }).then(response => {
            const isAuthenticated = (response.data.hasOwnProperty('status')
                && response.data.status === 'success'
                && response.data.hasOwnProperty('message')
                && response.data.message === 'login successful')
            resolve({isAuthenticated, message: ''})
        })
            .catch(error => {
                reject({message: error.response.data.message});
            });
    });
}

export function handleSaveMedia(appId, url, chatData) {
    return new Promise((resolve, reject) => {
        patientAPI.post('/external/appointment/addMedia', {
            'appId': appId,
            'mediaFile': url,
            'chat': chatData,
        }).then(response => {
            resolve()
        }).catch(error => {
            reject({message: error.response.data.error.message})
        })
    })
}

export function getUserInfo() {
    return new Promise((resolve, reject) => {
        API.get('/whoami').then(response => {
            const data = response.data;
            if (data.hasOwnProperty('status') && data['status'] === 'success')
                resolve({isAuthenticated: true});
            else
                reject(data['message'])
        })
            .catch(error => {
                reject(error)
            })
    })
}


export function createSession(sessionId) {
    return new Promise((resolve, reject) => {
        var data = JSON.stringify({customSessionId: sessionId})
        axios
            .post(OPENVIDU_SERVER_URL + '/api/sessions', data, {
                headers: {
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('CREATE SESION', response)
                resolve(response.data.id)
            })
            .catch((response) => {
                var error = Object.assign({}, response)
                if (error.response.status === 409) {
                    resolve(sessionId)
                } else {
                    console.log(error)
                    console.warn(
                        'No connection to OpenVidu Server. This may be a certificate error at ' +
                        OPENVIDU_SERVER_URL,
                    )
                    if (
                        window.confirm(
                            'No connection to OpenVidu Server. This may be a certificate error at "' +
                            OPENVIDU_SERVER_URL +
                            '"\n\nClick OK to navigate and accept it. ' +
                            'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                            OPENVIDU_SERVER_URL +
                            '"',
                        )
                    ) {
                        window.location.assign(OPENVIDU_SERVER_URL + '/accept-certificate')
                    }
                }
            })
    })
}

export function createToken(sessionId) {
    return new Promise((resolve, reject) => {
        var data = JSON.stringify({session: sessionId})
        axios
            .post(OPENVIDU_SERVER_URL + '/api/tokens', data, {
                headers: {
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('TOKEN', response)
                resolve(response.data.token)
            })
            .catch((error) => reject(error))
    })
}

export function startRecording(sessionId) {
    return new Promise((resolve, reject) => {
        const data = JSON.stringify({ session: sessionId, hasVideo: false });
        axios
            .post(`${OPENVIDU_SERVER_URL}/api/recordings/start`, data, {
                headers: {
                    Authorization: `Basic ${btoa(`OPENVIDUAPP:${OPENVIDU_SERVER_SECRET}`)}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                resolve(response.data.id);
            })
            .catch(error => reject(error));
    });
}

export function stopRecording(sessionId, recordingId) {
    return new Promise((resolve, reject) => {
        const data = JSON.stringify({ session: sessionId });
        axios
            .post(`${OPENVIDU_SERVER_URL}/api/recordings/stop/${recordingId}`, data, {
                headers: {
                    Authorization: `Basic ${btoa(`OPENVIDUAPP:${OPENVIDU_SERVER_SECRET}`)}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => reject(error));
    });
}
