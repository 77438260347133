import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    deviceList: {
        marginTop: '15px',
        padding: '15px',
        fontSize: '15px'
    },
    font: {
        fontSize: '15px'
    }
});

function DeviceList(props) {
    const {devices, value, chooseDevice, classes, caption} = props;

    return devices && devices.length > 0 && (
        <Paper elevation={3} className={classes.deviceList}>
            {caption}:&nbsp;
            <Select
                value={value}
                onChange={(e) => chooseDevice(e.target.value)}
                className={classes.font}>
                {devices.map((device, index) => (
                    <MenuItem key={`device-${index}`} value={device} className={classes.font}>
                        {device.label}
                    </MenuItem>
                ))}
            </Select>
        </Paper>

    );
}

export default withStyles(styles)(DeviceList);
