import axios from 'axios';
import { API_DOCTOR_BASE_URL, API_PATIENT_BASE_URL } from "../constants";

export default axios.create({
    baseURL: API_DOCTOR_BASE_URL,
    withCredentials: true
});

export const patientAPI = axios.create({
    baseURL: API_PATIENT_BASE_URL,
    withCredentials: true
});