import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import Send from '@material-ui/icons/Send';
import { Tooltip } from '@material-ui/core';

import {
  handleSaveMedia,
} from "../../actions";

import './ChatComponent.css';


export function ChatComponent(props) {
  const messageEl = useRef(null);
  const chatWindow = useRef(null);
  const { messageList, disabled, session, name, sessionId } = props;

  useEffect(() => {
    scrollToBottom();
  }, [messageList.length]);

  const scrollToBottom = () => {
    setTimeout(() => {
      try {
        if (chatWindow && chatWindow.current) {
          chatWindow.current.scrollTop = chatWindow.current.scrollHeight;
        }
      } catch (err) {}
    }, 20);
  };

  const sendMessage = () => {
    if (messageEl && messageEl.current && messageEl.current.value) {
      const now = new Date();
      const minutes = now.getMinutes();
      const hours = now.getHours();
      const data = {
        message: messageEl.current.value,
        nickname: name,
        streamId: 'streamID',
        time: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
      };
      session.signal({
        data: JSON.stringify(data),
        type: 'chat',
      });
      handleSaveMedia(sessionId, 'http://empty', JSON.stringify(data));
      messageEl.current.value = '';
    }
}

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div id="chatContainer">
      <div id="chatComponent">
        <div id="chatToolbar">
          <span>Чат</span>
        </div>
        <div className="message-wrap" ref={chatWindow}>
          {messageList.map((data, i) => (
            <div key={i} id="remoteUsers" className={clsx('message', data.nickname !== 'doctor' ? ' left' : ' right')}>
              <div className="msg-detail">
                <div className="msg-info">
                  <p>
                    {' '}
                    {data.nickname} {data.time}
                  </p>
                </div>
                <div className="msg-content">
                  <span className="triangle" />
                  <p className="text">{data.message}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div id="messageInput">
          <input
            placeholder="Отправить сообщение"
            id="chatInput"
            ref={messageEl}
            onKeyPress={handlePressKey}
            disabled={disabled}
          />
          <Tooltip title="Отправить сообщение">
            <div>
              <Fab size="small" id="sendButton" disabled={disabled} onClick={() => sendMessage()}>
                <Send />
              </Fab>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ChatComponent;
