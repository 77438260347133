import React, {Component} from 'react';
import {OpenVidu} from 'openvidu-browser';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import UserVideoComponent from './UserVideoComponent';

import {
    createSession,
    createToken,
    handleSaveMedia,
    startRecording,
    stopRecording
} from "./actions";

import './App.css';
import VideoToolbar from "./components/VideoToolbar/VideoToolbar";
import ChatComponent from "./components/chat/ChatComponent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import withStyles from "@material-ui/core/styles/withStyles";
import DeviceList from "./components/devices/Devices";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import DoctorName from "./components/doctor/DoctorName";

const styles = {
    snackbar: {
        backgroundColor: '#28a745',
        fontSize: '14px'
    },
    topMargin: {
        marginTop: '15px'
    },
    dialog: {
        flexWrap: 'nowrap',
    },
    videoWrapper: {
        minHeight: '341px',
        position: 'relative',
        height: '100%',
    },
    patientContainer: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '40%',
    },
    doctorContainer: {
        width: '100%',
        // backgroundColor: '#F5F5F5',
        height: '100%',
        textAlign: 'center',
    },
    doctorImage: {
        minHeight: '335px',
    },
    hidden: {
        display: 'none',
    },
    alertNoDoctor: {
        textAlign: 'center',
        color: 'red',
    },
    actionsButton: {
        marginTop: '15px',
        padding: '15px',
    },
    actionButton: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    beginButton: {
        backgroundColor: '#1976d2'
    }
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVideoActive: false,
            isAudioActive: true,
            session: undefined,
            sessionId: undefined,
            mainStreamManager: undefined,
            publisher: undefined,
            subscribers: [],
            messageList: [],
            recordingId: undefined,
            videoUrl: undefined,
            isSending: false,
            isSent: false,
            errors: null,
            hasMediaErrors: false,
            isNotificationOpen: false,
            audioDevices: [],
            videoDevices: [],
            selectedAudioDevice: null,
            selectedVideoDevice: null,
            doctorName: "Доктор",
        };
        this.url = `${process.env.PUBLIC_URL}/resources/sound.mp3`;
        this.audio = new Audio(this.url);

        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.sendSignalUserChanged = this.sendSignalUserChanged.bind(this);
        this.playSound = this.playSound.bind(this);
        this.handleNotificationClose = this.handleNotificationClose.bind(this);
        this.handleNotificationOpen = this.handleNotificationOpen.bind(this);
        this.handleChooseAudioDevice = this.handleChooseAudioDevice.bind(this);
        this.handleChooseVideoDevice = this.handleChooseVideoDevice.bind(this);
        this.handleStartReception = this.handleStartReception.bind(this);
        this.handleChangeDoctorName = this.handleChangeDoctorName.bind(this);
    }

    getDevices(OV) {
        OV.getDevices().then(devices => {
            const audioDeviceList = devices.filter(device => device.kind === "audioinput");
            const videoDeviceList = devices.filter(device => device.kind === "videoinput");
            const defaultAudioDevice = audioDeviceList.find(item => item.deviceId === 'default');
            const defaultVideoDevice = videoDeviceList.find(item => item.deviceId === 'default');
            this.setState({
                audioDevices: audioDeviceList,
                videoDevices: videoDeviceList,
                selectedAudioDevice: defaultAudioDevice ? defaultAudioDevice : audioDeviceList[0],
                selectedVideoDevice: defaultVideoDevice ? defaultVideoDevice : videoDeviceList[0],
            });
        });
    }

    chooseDevices() {
        const OV = new OpenVidu();
        OV.getUserMedia({
            audioSource: undefined,
            videoSource: undefined,
        }).then(mediaStream => {
            this.getDevices(OV);
        }, (error) => {
            // тогда смотрим только аудио устройства(микрофон)
            if (error.message === "NotFoundError: Requested device not found") {
                OV.getUserMedia({
                    audioSource: undefined,
                    videoSource: false,
                }).then(mediaStream => {
                    this.getDevices(OV);
                });
            }
        });

    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onbeforeunload);

        const urlParams = new URLSearchParams(window.location.search);
        let sessionId = null;
        if (urlParams.get('session')) {
            sessionId = urlParams.get('session');
            this.setState({sessionId});
        }

        this.chooseDevices();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    handleChangeSessionId(e) {
        this.setState({
            mySessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            myUserName: e.target.value,
        });
    }

    handleChangeDoctorName(e) {
        this.setState({
            doctorName: e.target.value,
        });
    }

    handleMainVideoStream(stream) {
        if (this.state.mainStreamManager !== stream) {
            this.setState({
                mainStreamManager: stream
            });
        }
    }

    deleteSubscriber(streamManager) {
        let subscribers = this.state.subscribers;
        let index = subscribers.indexOf(streamManager, 0);
        if (index > -1) {
            subscribers.splice(index, 1);
            this.setState({
                subscribers: subscribers,
            });
        }
    }

    startVideoRecording() {
        if (this.state.sessionId && !this.state.hasMediaErrors) {
            startRecording(this.state.sessionId).then(id => {
                this.setState({
                    recordingId: id
                });
            }, error => {
                // if (!this.state.errors) {
                //     this.setState({
                //         errors: 'Запись видео не запущена, Сессия с таким ид уже сохраняется в данный момент'
                //     })
                // }
            });
        }
    }

    stopVideoRecording() {
        if (this.state.sessionId && this.state.recordingId) {
            stopRecording(this.state.sessionId, this.state.recordingId).then(res => {
                this.setState({recordingId: undefined, videoUrl: [...this.state.videoUrls, res.url]});
            });
        }
    }

    handlePublisherError(publisher) {
        publisher.once('accessDenied', (e) => {
            let errorText = '';
            if (e.name === 'DEVICE_ACCESS_DENIED') {
                errorText = 'Доступ к медиа устройствам не предоставлен.';
            } else if (e.name === 'NO_INPUT_SOURCE_SET') {
                errorText = 'Видео или аудио устройства не найдены.';
            } else if (e.name === 'INPUT_VIDEO_DEVICE_NOT_FOUND') {
                errorText = 'Видео устройство не найдено.';
            } else {
                errorText = e.name;
            }
            if (errorText) {
                this.setState({
                    errors: errorText,
                    hasMediaErrors: true,
                });
            }
        });
    }

    joinSession() {
        const {isVideoActive, isAudioActive, sessionId, selectedAudioDevice, selectedVideoDevice} = this.state;

        this.OV = new OpenVidu();
        // --- 2) Init a session ---

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                var mySession = this.state.session;

                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                    var subscriber = mySession.subscribe(event.stream, undefined);
                    var subscribers = this.state.subscribers;
                    subscribers.push(subscriber);

                    // Update the state with the new subscribers
                    this.setState({
                        subscribers: subscribers,
                    });
                    this.playSound();
                    this.handleNotificationOpen();
                });

                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {

                    // Remove the stream from 'subscribers' array
                    this.deleteSubscriber(event.stream.streamManager);
                });

                mySession.on('signal:chat', (event) => {
                    // @ts-ignore
                    const data = JSON.parse(event.data);
                    let messageList = this.state.messageList;
                    messageList.push({
                        streamId: event.from.connectionId,
                        nickname: data.nickname,
                        message: data.message,
                        time: data.time,
                    });
                    this.setState({
                        messageList
                    });
                });

                // --- 4) Connect to the session with a valid user token ---

                // 'getToken' method is simulating what your server-side should do.
                // 'token' parameter should be retrieved and returned by your own backend
                this.getToken(sessionId).then((token) => {
                    // First param is the token got from OpenVidu Server. Second param can be retrieved by every user on event
                    // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
                    mySession
                        .connect(
                            token,
                            {clientData: this.state.myUserName},
                        )
                        .then(() => {

                            // --- 5) Get your own camera stream ---
                            // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                            // element: we will manage it on our own) and with the desired properties
                            let publisher = this.OV.initPublisher(undefined, {
                                audioSource: selectedAudioDevice ? selectedAudioDevice : undefined, // The source of audio. If undefined default microphone
                                videoSource: selectedVideoDevice ? selectedVideoDevice : false, // The source of video. If undefined default webcam
                                publishAudio: isAudioActive, // Whether you want to start publishing with your audio unmuted or not
                                publishVideo: isVideoActive, // Whether you want to start publishing with your video enabled or not
                                resolution: '640x480', // The resolution of your video
                                frameRate: 30, // The frame rate of your video
                                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                                mirror: false, // Whether to mirror your local video or not
                            });

                            this.handlePublisherError(publisher);

                            if (!this.state.errors) {
                                this.startVideoRecording();

                                // --- 6) Publish your stream ---
                                mySession.publish(publisher);

                                // Set the main video in the page to display our webcam and store our Publisher
                                this.setState({
                                    mainStreamManager: publisher,
                                    publisher: publisher,
                                });
                            }

                        })
                        .catch((error) => {
                            console.log('There was an error connecting to the session:', error.code, error.message);
                        });
                });
            },
        );
    }

    saveMedia(videoUrl) {
        const { sessionId } = this.state;
        handleSaveMedia(
            sessionId,
            videoUrl,
            {}
        ).then(res => {
            this.setState({isSending: false, isSent: true});
        }, error => {
            this.setState({errors: error.message, isSending: false, isSent: true});
        });
    }

    leaveSession() {
        // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
        const mySession = this.state.session;

        if (mySession) {
            mySession.disconnect();
        }

        if (this.state.sessionId && this.state.recordingId && !this.state.errors) {
            this.setState({isSending: true});
            stopRecording(this.state.sessionId, this.state.recordingId).then(res => {
                this.setState({recordingId: undefined, videoUrl: res.url});
                this.saveMedia(res.url);
            });
        }


        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: null,
            myUserName: '',
            mainStreamManager: undefined,
            publisher: undefined,
            errors: null,
            messageList: [],
            hasMediaErrors: false,
        });
    }

    sendSignalUserChanged(data) {
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        this.state.session.signal(signalOptions);
    }

    camStatusChanged() {
        const isVideoActive = this.state.isVideoActive;
        this.state.mainStreamManager.publishVideo(!isVideoActive);
        this.sendSignalUserChanged(!isVideoActive);
        this.setState({isVideoActive: !isVideoActive});
    }

    micStatusChanged() {
        const isAudioActive = this.state.isAudioActive;
        this.state.mainStreamManager.publishAudio(!isAudioActive);
        this.sendSignalUserChanged(!isAudioActive);
        this.setState({isAudioActive: !isAudioActive});
    }

    playSound() {
        this.audio.play();
    }

    handleNotificationOpen() {
        this.setState({
            isNotificationOpen: true
        });
    }

    handleNotificationClose() {
        this.setState({
            isNotificationOpen: false
        });
    }

    handleChooseAudioDevice(selectedDevice) {
        this.setState({selectedAudioDevice: selectedDevice});
    }

    handleChooseVideoDevice(selectedDevice) {
        this.setState({selectedVideoDevice: selectedDevice});
    }

    handleStartReception() {
        this.leaveSession();
        this.joinSession();
    }

    render() {
        const {
            messageList, session, isSending, isSent, errors, subscribers, hasMediaErrors,
            isNotificationOpen, audioDevices, videoDevices,
            selectedAudioDevice, selectedVideoDevice, sessionId, mainStreamManager, isVideoActive, doctorName
        } = this.state;
        const isChatDisabled = session === undefined;
        const patient = subscribers && subscribers.length > 0 ? subscribers[0] : null;
        const {classes} = this.props;
        return (
            <Container>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={4}
                    className={classes.dialog}
                >
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        ContentProps={{
                            classes: {
                                root: classes.snackbar
                            }
                        }}
                        open={isNotificationOpen}
                        autoHideDuration={4000}
                        onClose={this.handleNotificationClose}
                        message="Пациент присоединился"
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit"
                                            onClick={this.handleNotificationClose}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="center"
                              alignItems="stretch">
                            <Grid item>
                                <DeviceList devices={audioDevices} value={selectedAudioDevice}
                                            chooseDevice={this.handleChooseAudioDevice}
                                            caption="Выберите аудио устройство"/>
                            </Grid>
                            <Grid item>
                                <DeviceList devices={videoDevices} value={selectedVideoDevice}
                                            chooseDevice={this.handleChooseVideoDevice}
                                            caption="Выберите видео устройство"/>
                            </Grid>
                            <Grid item>
                                <DoctorName
                                    disabled={!!session}
                                    value={doctorName}
                                    onChange = {this.handleChangeDoctorName}
                                />
                            </Grid>
                            <Grid item>
                                <Paper elevation={10} className={classes.actionsButton}>
                                    <Grid container>
                                        <Grid item md={8}>
                                            <Typography variant="h3">Телеприем</Typography>
                                        </Grid>
                                        <Grid item md={2} className={classes.actionButton}>
                                            <Button
                                                disabled={!sessionId || !selectedAudioDevice || !!session}
                                                onClick={this.handleStartReception}
                                                variant="contained"
                                                className={classes.beginButton}
                                                color="primary"
                                                size="large">
                                                Начать прием
                                            </Button>
                                        </Grid>
                                        <Grid item md={2} className={classes.actionButton}>
                                            <Button
                                                disabled={!session}
                                                onClick={this.leaveSession}
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                            >
                                                Закончить прием
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <VideoToolbar
                            isVideoActive={this.state.isVideoActive}
                            isAudioActive={this.state.isAudioActive}
                            micStatusChanged={this.micStatusChanged}
                            camStatusChanged={this.camStatusChanged}
                            leaveSession={this.leaveSession}
                            isDisabled={!!hasMediaErrors || !session}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            <Grid item md xs={12} sm={12}>
                                {!!mainStreamManager ? (
                                    <div className={classes.videoWrapper}>
                                        <div className={classes.doctorContainer}>
                                            {patient && <UserVideoComponent streamManager={patient}/>}
                                        </div>
                                        <div
                                            className={clsx(classes.patientContainer, {[classes.hidden]: !isVideoActive})}>
                                            <UserVideoComponent streamManager={mainStreamManager}/>
                                        </div>
                                    </div>
                                ) : null}
                            </Grid>


                            <Grid item md xs={12} sm={12}>
                                <Paper elevation={3}>
                                    <ChatComponent
                                        messageList={messageList}
                                        disabled={isChatDisabled}
                                        session={session}
                                        name={doctorName}
                                        sessionId={sessionId}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {!sessionId && <h4 style={{color: 'red'}}>Идентификатор сессии не задан</h4>}
                        {isSending && !isSent && <h4>Пожалуйста подождите, идет обработка данных</h4>}
                        {errors && <h4 style={{color: 'red'}}>Ошибки: {errors} </h4>}
                        {!errors && !isSending && isSent && <h4 style={{color: 'green'}}>Данные успешно сохранены </h4>}
                        {this.state.videoUrl && (<div><a href={this.state.videoUrl}>{this.state.videoUrl}</a></div>)}
                    </Grid>
                </Grid>
            </Container>
        );
    }

    /**
     * --------------------------
     * SERVER-SIDE RESPONSIBILITY
     * --------------------------
     * These methods retrieve the mandatory user token from OpenVidu Server.
     * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
     * the API REST, openvidu-java-client or openvidu-node-client):
     *   1) Initialize a session in OpenVidu Server    (POST /api/sessions)
     *   2) Generate a token in OpenVidu Server        (POST /api/tokens)
     *   3) The token must be consumed in Session.connect() method
     */

    getToken(sessionId) {
        return createSession(sessionId).then((sessionId) => createToken(sessionId));
    }

}

export default withStyles(styles)(App);
